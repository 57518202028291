window.drinkityDrink = function drinkityDrink() {
  return {
    // default properties
    apiHost: 'https://api.tikibot.ai', // set environment variable for host
    // apiHost: 'http://localhost:10000',
    hasTouch: matchMedia('(hover: none)').matches,
    optionsOpen: false,
    optionSelected: "drinkwith",
    buttonText: "Let's make a drink",
    options: [
      {
        slug: "surprise",
        label: "Surprise me with a drink.",
        button: "Let's make a drink",
        selected: false,
        showChevron: false
      },{
        slug: "drinkwith",
        label: "Make me a drink with...",
        button: "Let's make a drink",
        selected: true,
        showChevron: true
      },{
        slug: "drinkcalled",
        label: "Make me a drink called...",
        button: "Let's make a drink",
        selected: false,
        showChevron: false
      },{
        slug: "drinktaste",
        label: "Make me a drink that tastes like...",
        button: "Let's make a drink",
        selected: false,
        showChevron: false
      },{
        slug: "alternative",
        label: "Find an alternative to...",
        button: "Find alternative",
        selected: false,
        showChevron: false
      },{
        slug: "alter",
        label: "Alter this recipe...",
        button: "Alter recipe",
        selected: false,
        showChevron: false
      },{
        slug: "identify-item",
        label: "Inventory this...",
        button: "Take Photo",
        selected: false,
        showChevron: false
      }
    ],
    showIngredientOverlay: false,
    suggestions: ["Lime Juice", "Falernum", "Orgeat", "Dark Rum", "Simple Syrup", "Fassionola"],
    newIngredient: '',
    drinkName: '',
    drinkTaste: '',
    ingredientName: '',
    showImageUpload: false,
    ingredientImage: [],
    showAlterOverlay: false,
    alterAmount: '1/2',
    recipe: '',
    ingredients: [],
    ingredientCTA: '+ Ingredient',
    isLoading: false,
    tryingAgain: false,
    responseReceived: false,
    response: '',
    selectedText: '',
    selectedResponse: '',
    showSelectMenu: false,
    gettingSelectionResponse: false,
    receivedSelectionResponse: false,
    copiedToClipboard: false,
    addSuggestion(index) {
      this.newIngredient = this.suggestions[index];
    },
    openIngredientOverlay() {
      // if (this.ingredients.length === 7) {
      //   alert("At this time there is a maximum of 7 ingredients (this will increase soon!).");
      // } else {
      //   trackEvent('Ingredient Overlay Opened');
        this.showIngredientOverlay = true;
      // }
    },
    removeIngredient(index) {
      trackEvent('Ingredient Removed')
      this.ingredients.splice(index, 1);

      if (this.ingredients.length === 0) {
        this.ingredientCTA = '+ Ingredient';
      }
    },
    addIngredient() {
      if (this.newIngredient === '') {
        alert("You haven't added an ingredient yet.");
      } else {
        this.ingredients.push(this.newIngredient);
        this.ingredientCTA = 'Add Another Ingredient';
        this.newIngredient = '';
        trackEvent('Ingredient Added')
        this.showIngredientOverlay = false;
      }
    },
    fadeBG(elem) {
      var objDiv = document.getElementById("ingredients");
      objDiv.scrollTop = objDiv.scrollHeight;
      setTimeout(() => {
        elem.classList.add('ingredientRemoved');    
        setTimeout(() => {
          elem.classList.remove('ingredientAdded');
          elem.classList.remove('ingredientRemoved');
        }, "2000");
      }, "2000");
    },
    selectFile(event) {
        this.ingredientImage = event.target.files[0]
    },
    getIngredientsFromImage() {
      if (this.ingredientImage.length === 0) {
        alert("You haven't selected an image yet.");
      } else {
        const that = this;
        this.optionSelected = 'drink-image';
        this.isLoading = true;
        this.showIngredientOverlay = false;
        this.showImageUpload = false;
        const image = this.ingredientImage;
        const filename = image.name;
        const extension = image.type;
        const data = new FormData();
        data.append('file', image);
        data.append('filename', filename);
        data.append('type', extension);

        fetch(this.apiHost + '/prompt/drink/from-image', {
          method: 'POST',
          body: data
        })
        .then((response) => {
          if (response.status === 200) {
            return response.json();
          } else {
            alert("Tiki go blurp.");
            that.isLoading = false;
          }
        })
        .then((data) => {
          if (data.status === 200) {
            trackEvent('Response: Image Cocktail');
            that.response = '<p>Based on the items in the image I\'ve come up with this:</p>' + data.response;
            that.isLoading = false;
            that.responseReceived = true;
            that.ingredientImage = [];
          } else {
            alert("Tiki go blurp.");
            that.isLoading = false;
          }
        })
        .catch(() => {
          alert('Uhoh! Something went wrong.');
          that.isLoading = false;
        });
      }
    },
    doTheToggle(index) {
      let setTrackEvent = false;
      if (this.optionsOpen === true) {
        setTrackEvent = true;
        this.optionsOpen = false;
      } else {
        this.optionsOpen = true;
      }
      for (let i = 0; i < this.options.length; i++) {
        if (i === index) {
          this.options[i].selected = true;
          this.options[i].showChevron = true;
          this.optionSelected = this.options[i].slug;
          this.buttonText = this.options[i].button;
          if (setTrackEvent) {
            trackEvent('Toggle: ' + this.options[i].slug);
          }
        } else {
          this.options[i].showChevron = false;
          if (this.optionsOpen) {
            this.options[i].selected = true;
          } else {
            this.options[i].selected = false;
          }
        }
      }
    },
    getAnAnswer() {
      if (this.optionSelected === "surprise") {
        trackEvent('Request: Surprise Me');
        this.surpriseMe();
      } else if (this.optionSelected === "drinkwith") {
        trackEvent('Request: Drink by Ingredient');
        this.getMeADrink();
      } else if (this.optionSelected === 'drinkcalled') {
        trackEvent('Request: Drink by Name');
        this.getMeADrinkByThisName();
      } else if (this.optionSelected === 'drinktaste') {
        trackEvent('Request: Drink by Taste');
        this.getMeADrinkByTasteProfile();
      } else if (this.optionSelected === 'alternative') {
        trackEvent('Request: Alternative');
        this.findAlternative();
      } else if (this.optionSelected === 'alter') {
        trackEvent('Request: Alter Recipe');
        this.alterThis();
      } else if (this.optionSelected === 'identify-item') {
        trackEvent('Request: Inventory This');
        this.getItemFromImage();
      } else {
        alert('tiki fail.');
      }
    },
    surpriseMe() {
      this.isLoading = true;
      // get response
      const that = this;
      const params = {
        prompt: this.optionSelected
      };
      fetch(this.apiHost + '/prompt/drink/surprise', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(params)
      })
      .then((response) => {
        if (response.status === 200) {
          return response.json();
        } else {
          alert("Tiki go blurp.");
          that.isLoading = false;
        }
      })
      .then((data) => {
        if (data.status === 200) {
          // insert response into response variable
          trackEvent('Response: Surprise Me');
          that.response = data.response;
          that.isLoading = false;
          that.responseReceived = true;
        } else {
          alert("Tiki go blurp.");
          that.isLoading = false;
        }
      })
      .catch(() => {
        alert('Uhoh! Something went wrong.');
        that.isLoading = false;
      });
    },
    getMeADrink() {
      if (this.ingredients.length === 0) {
        alert("You haven't added any ingredients yet.");
      } else {
        this.isLoading = true;
        // get response
        const that = this;
        const params = {
          prompt: this.optionSelected,
          ingredients: this.ingredients
        };
        fetch(this.apiHost + '/prompt/drink', {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(params)
        })
        .then((response) => {
          if (response.status === 200) {
            return response.json();
          } else {
            alert("Tiki go blurp.");
            that.isLoading = false;
            that.tryingAgain = false;
          }
        })
        .then((data) => {
          if (data.status === 200) {
            // insert response into response variable
            trackEvent('Response: Drink by Ingredient');
            that.response = '<h1>Here\'s what I came up with based on your ingredients.</h1>' + data.response;
            that.isLoading = false;
            that.tryingAgain = false;
            that.responseReceived = true;
          } else {
            alert("Tiki go blurp.");
            that.isLoading = false;
            that.tryingAgain = false;
          }
        })
        .catch(() => {
          alert('Uhoh! Something went wrong.');
          that.isLoading = false;
          that.tryingAgain = false;
        });
      }
    },
    getMeADrinkByThisName() {
      if (this.drinkName === '') {
        alert('You haven\'t provided a drink yet.');
      } else {
        this.isLoading = true;
        // get response
        const that = this;
        const params = {
          prompt: this.optionSelected,
          drink: this.drinkName
        };
        fetch(this.apiHost + '/prompt/drink/name', {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(params)
        })
        .then((response) => {
          if (response.status === 200) {
            return response.json();
          } else {
            alert("Tiki go blurp.");
            that.isLoading = false;
            that.tryingAgain = false;
          }
        })
        .then((data) => {
          if (data.status === 200) {
            // insert response into response variable
            trackEvent('Response: Drink by Name');
            that.response = data.response;
            that.isLoading = false;
            that.tryingAgain = false;
            that.responseReceived = true;
          } else {
            alert("Tiki go blurp.");
            that.isLoading = false;
            that.tryingAgain = false;
          }
        })
        .catch(() => {
          alert('Uhoh! Something went wrong.');
          that.isLoading = false;
          that.tryingAgain = false;
        });
      }
    },
    getMeADrinkByTasteProfile() {
      if (this.drinkTaste === '') {
        alert('You haven\'t provided a taste profile yet.');
      } else {
        this.isLoading = true;
        // get response
        const that = this;
        const params = {
          prompt: this.optionSelected,
          taste: this.drinkTaste
        };
        fetch(this.apiHost + '/prompt/drink/taste', {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(params)
        })
        .then((response) => {
          if (response.status === 200) {
            return response.json();
          } else {
            alert("Tiki go blurp.");
            that.isLoading = false;
            that.tryingAgain = false;
          }
        })
        .then((data) => {
          if (data.status === 200) {
            // insert response into response variable
            trackEvent('Response: Drink by Taste');
            that.response = data.response;
            that.isLoading = false;
            that.tryingAgain = false;
            that.responseReceived = true;
          } else {
            alert("Tiki go blurp.");
            that.isLoading = false;
            that.tryingAgain = false;
          }
        })
        .catch(() => {
          alert('Uhoh! Something went wrong.');
          that.isLoading = false;
          that.tryingAgain = false;
        });
      }
    },
    findAlternative() {
      if (this.ingredientName === '') {
        alert('You provided an ingredient yet.');
      } else {
        this.isLoading = true;
        // get response
        const that = this;
        const params = {
          prompt: this.optionSelected,
          ingredient: this.ingredientName
        };
        fetch(this.apiHost + '/prompt/alternative', {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(params)
        })
        .then((response) => {
          if (response.status === 200) {
            return response.json();
          } else {
            alert("Tiki go blurp.");
            that.isLoading = false;
          }
        })
        .then((data) => {
          if (data.status === 200) {
            // insert response into response variable
            trackEvent('Response: Alternative');
            that.response = '<h1>Possible alternatives to ' + that.ingredientName + ' are:</h1>' + data.response
            that.isLoading = false;
            that.responseReceived = true;
          } else {
            alert("Tiki go blurp.");
            that.isLoading = false;
          }
        })
        .catch(() => {
          alert('Uhoh! Something went wrong.');
          that.isLoading = false;
        });
      }
    },
    alterThis() {
      if (this.recipe === '') {
        alert('You provided a recipe yet.');
      } else {
        this.isLoading = true;
        // get response
        const that = this;
        const params = {
          prompt: this.optionSelected,
          recipe: this.recipe,
          amount: this.alterAmount
        };
        fetch(this.apiHost + '/prompt/alter', {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(params)
        })
        .then((response) => {
          if (response.status === 200) {
            return response.json();
          } else {
            alert("Tiki go blurp.");
            that.isLoading = false;
          }
        })
        .then((data) => {
          if (data.status === 200) {
            // insert response into response variable
            trackEvent('Response: Alter Recipe');
            that.response = data.response;
            that.isLoading = false;
            that.responseReceived = true;
          } else {
            alert("Tiki go blurp.");
            that.isLoading = false;
          }
        })
        .catch(() => {
          alert('Uhoh! Something went wrong.');
          that.isLoading = false;
        });
      }
    },

    getItemFromImage() {
      // if (this.ingredientImage.length === 0) {
      //   alert("You haven't selected an image yet.");
      // } else {
        const that = this;
        // this.optionSelected = 'drink-image';
        // this.isLoading = true;
        // this.showIngredientOverlay = false;
        // this.showImageUpload = false;
        const image = this.ingredientImage;
        const filename = image.name;
        const extension = image.type;
        const data = new FormData();
        data.append('file', image);
        data.append('filename', filename);
        data.append('type', extension);

        fetch(this.apiHost + '/prompt/inventory/from-image', {
          method: 'POST',
          body: data
        })
        .then((response) => {
          if (response.status === 200) {
            return response.json();
          } else {
            alert("Tiki go blurp.");
            that.isLoading = false;
          }
        })
        .then((data) => {
          if (data.status === 200) {
            alert('done. check the inspector.')
            // trackEvent('Response: Image Cocktail');
            // that.response = '<p>Based on the items in the image I\'ve come up with this:</p>' + data.response;
            // that.isLoading = false;
            // that.responseReceived = true;
            // that.ingredientImage = [];
          } else {
            alert("Tiki go blurp.");
            that.isLoading = false;
          }
        })
        .catch(() => {
          alert('Uhoh! Something went wrong.');
          that.isLoading = false;
        });
      // }
    },
    
    tryAgain() {
      this.tryingAgain = true;
      if (this.optionSelected === 'drinkwith') {
        trackEvent('Request: Try Again by Ingredient');
        this.getMeADrink();
      } else if (this.optionSelected === 'drinkcalled') {
        trackEvent('Request: Try Again by Name');
        this.getMeADrinkByThisName();
      } else if (this.optionSelected === 'drinktaste') {
        trackEvent('Request: Try Again by Taste');
        this.getMeADrinkByTasteProfile();
      } else {
        alert('tiki tiki burp. Something went awry.');
      }
    },

    defineSelectedText() {
      this.showSelectMenu = false;
      this.selectedResponse = '';
      this.gettingSelectionResponse = true;
      trackEvent('Request: Define Selected Text');
      // get response
      const that = this;
      const params = {
        text: this.selectedText
      };
      fetch(this.apiHost + '/prompt/text/define', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(params)
      })
      .then((response) => {
        if (response.status === 200) {
          return response.json();
        } else {
          alert("Tiki go blurp.");
          that.isLoading = false;
        }
      })
      .then((data) => {
        if (data.status === 200) {
          // insert response into response variable
          trackEvent('Response: Define Selected Text');
          that.selectedResponse = '<h1>' + that.selectedText + ' is...</h1>' + data.response;
          that.gettingSelectionResponse = false;
          that.receivedSelectionResponse = true;
        } else {
          alert("Tiki go blurp.");
          that.isLoading = false;
        }
      })
      .catch(() => {
        alert('Uhoh! Something went wrong.');
        that.isLoading = false;
      });
    },
    howToMakeSelectedText() {
      this.showSelectMenu = false;
      this.selectedResponse = '';
      this.gettingSelectionResponse = true;
      trackEvent('Request: How to Make Selected Text');
      // get response
      const that = this;
      const params = {
        text: this.selectedText
      };
      fetch(this.apiHost + '/prompt/text/make', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(params)
      })
      .then((response) => {
        if (response.status === 200) {
          return response.json();
        } else {
          alert("Tiki go blurp.");
          that.isLoading = false;
        }
      })
      .then((data) => {
        if (data.status === 200) {
          // insert response into response variable
          trackEvent('Response: How to Make Selected Text');
          that.selectedResponse = '<h1>Here\'s how to make ' + that.selectedText + '...</h1>' + data.response;
          that.gettingSelectionResponse = false;
          that.receivedSelectionResponse = true;
        } else {
          alert("Tiki go blurp.");
          that.isLoading = false;
        }
      })
      .catch(() => {
        alert('Uhoh! Something went wrong.');
        that.isLoading = false;
      });
    },
    selectedTextMenu() {
      let menu = document.getElementById("textSelectMenu");
      let selection = document.getSelection(), text = selection.toString();
      if (text !== "") {
        let rect = selection.getRangeAt(0).getBoundingClientRect();
        menu.style.top = `calc(${rect.top}px + 38px)`;
        menu.style.left = `calc(${rect.left}px + calc(${rect.width}px / 2) - 96px)`;
        // control['text']= text; 
        // document.body.appendChild(control);
        this.selectedText = text;
        this.showSelectMenu = true;
      } else {
        this.showSelectMenu = false;
      }
    },
    copyResponse() {
      var r = document.createRange();
      if (this.receivedSelectionResponse) {
        r.selectNode(document.getElementById('selectedResponse'));
      } else {
        r.selectNode(document.getElementById('response'));
      }
      window.getSelection().removeAllRanges();
      window.getSelection().addRange(r);
      document.execCommand('copy');
      window.getSelection().removeAllRanges();
      trackEvent('Response copied');
      this.copiedToClipboard = true;
      setTimeout(() => {
        this.copiedToClipboard = false;
      }, "2000");
    }
  }
}

window.trackEvent = function trackEvent(event) {
  mixpanel.track(event)
}